:root {
  /*Color palette**/
  --red-primary: #ea2c54;
  --red-middle: #ffd9e0;
  --red-light: #fff0f3;
  --white-hover: #fafafa;
  --pure-white: #ffffff;
  --secondary: #b8bcbd;
  --succes: #58be6a;
  --danger: #ef4444;
  --warning: #f2d069;
  --info: #59bab7;
  --black-primay: #2d2d2d;
  --gray-primary: #d6dadb;
  --gray-secundary: #969696;
  --disabled: #bfc2c6; /*Color for disabled elements*/
  /*End Color palette**/

  /* base */
  --base-color: #ea2c54;
  --lightest-color: #fff0f3;
  --lighter-color: #ffd9e0;
  --intermediate-color: #ef90a4;
  --darker-color: #ba3e51;
  --darkest-color: #8c2738;
  --white-color: #ffffff;
  --event-base-color: #ffdae2;
  --top-overlay: 0;
  --height-overlay: auto;
  --font-family-mulish: "Mulish", sans-serif;
  --font-family-rubik: "Rubik", sans-serif;
  --gray: #eeeeee;

  --optional: " - Optional---";
  /* complements */
  --filter-base: invert(27%) sepia(67%) saturate(4893%) hue-rotate(334deg)
    brightness(97%) contrast(89%) !important;
  --border-radius-right: 0 3px 3px 0 !important;
  --border-radius-left: 3px 0px 0px 3px;
}
body {
  background-color: #f6f6f6;
  font-family: var(--font-family-mulish);
  font-size: 12px;
}
p {
  margin-bottom: 5px;
}

.fas {
  color: var(--base-color);
}

/** Button **/
.btn {
  text-align: center;
  font-family: var(--font-family-mulish);
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0;
  color: var(--black-primay);
  background-color: #ffffff;
}

.btn-primary {
  background-color: var(--base-color);
  border-color: var(--base-color);
  color: #ffffff;
}

.btn-outline-danger {
  color: var(--base-color);
}

.btn-primary:hover {
  background-color: var(--darker-color);
  border-color: var(--base-color);
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: var(--base-color) !important;
  border-color: var(--red-middle) !important;
  box-shadow: 0 0 0 0.25rem rgb(255 217 224) !important;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: var(--base-color);
  border-color: var(--lighter-color);
  pointer-events: unset;
  cursor: not-allowed;
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  pointer-events: unset;
  cursor: not-allowed;
}

.btn-info {
  background-color: var(--info);
  border-color: var(--info);
  color: #ffffff;
}

.btn-info:hover {
  background-color: var(--info);
  border-color: var(--info);
  color: #ffffff;
}
.btn-info:focus {
  color: #fff;
  background-color: var(--info) !important;
  border-color: var(--info) !important;
}

.btn.btn-link:focus {
  outline: 0;
  box-shadow: none;
}

.btn-link {
  color: var(--base-color) !important;
  text-decoration: none;
}

.btn-link:hover {
  color: var(--darker-color) !important;
}

.btn i {
  margin-right: 6px;
}

.c-pointer {
  cursor: pointer;
}

.dropdown {
  position: initial;
}

.dropdown-toggle::after {
  margin-left: 0.8em;
  vertical-align: 0.1em;
}
.noCaretDd .dropdown-toggle::after {
  display: none;
}

.form-label.required::after,
.v2.form input[type="checkbox"] + label.required::after {
  content: "*";
  color: var(--danger);
  margin-left: 4px;
  font-size: 17px;
}

.form-label.optional::after {
  content: attr(data-text);
  font-style: italic;
  font-size: 12px;
  color: var(--gray-secundary);
}

span.required {
  color: var(--danger);
  margin-left: 4px;
  font-size: 17px;
}
span.optional {
  font-style: italic;
  font-size: 12px;
  font-weight: bold;
  color: var(--gray-secundary);
}

.dropdown-item {
  font-size: 12px;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #1e2125;
  background-color: var(--red-light);
}

.dropdown-menu {
  padding: 0.15rem 0;
}
/** End Button **/

/** Checkbox **/
.v2 input[type="checkbox"] + label {
  color: var(--black-primay);
}

.v2 input[type="checkbox"]:checked + label {
  color: var(--black-primay);
}

.v2 input[type="checkbox"] + label:before {
  content: "";
  width: 1em;
  height: 1em;
  float: left;
  margin: 0.35em 0.5em 0 0;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0.25em;
  background: #fff;
}

.v2 input[type="checkbox"]:checked + label:before {
  border-color: var(--base-color);
  background-color: var(--base-color);
}

.v2 input[type="checkbox"]:checked + label:after {
  content: "";
  width: 10px;
  height: 5px;
  border: 0.18em solid #ffffff;
  float: left;
  margin-left: -1.3em;
  border-right: 0;
  border-top: 0;
  margin-top: 0.6em;
  transform: rotate(313deg);
}

/** Tapa el input **/
.v2 input[type="checkbox"] {
  display: none;
}

.v2 input[type="checkbox"] + label {
  font-weight: normal;
  line-height: 1.7em;
  cursor: pointer;
}

.form-switch input[type="checkbox"] {
  cursor: pointer;
}

/** End Checkbox **/

/** Account status list text **/
.list-text {
  font-size: 11px;
  text-align: center;
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 2px;
}

/** Badges **/
.bg-secondary {
  background-color: var(--secondary) !important;
}

.bg-success {
  background-color: var(--succes) !important;
}

.bg-danger {
  background-color: var(--danger) !important;
}

.bg-warning {
  background-color: var(--warning) !important;
}

.bg-info {
  background-color: var(--info) !important;
}
/** End Badges **/

/** Checkbox **/

.form-switch .form-check-input:focus {
  background-image: url("/src/img/switch.svg");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("/src/img/switch-checked.svg") !important;
}

.form-check-input:enabled {
  cursor: pointer;
}

.form-check-input:disabled {
  pointer-events: unset;
  cursor: not-allowed;
}

.form-check-input:checked {
  background-color: var(--base-color);
  border-color: var(--base-color);
}

/** End Checkbox **/

/** Modal **/
.modal-header {
  border-bottom: 1px solid transparent;
}

.modal-title {
  color: var(--base-color);
}

.modal-title p:nth-child(1) {
  color: var(--bs-body-color);
  font-size: 14px;
  line-height: 1.5;
  font-weight: 300;
}

.modal-footer {
  border-top: 1px solid transparent;
}

.modal-xxl {
  max-width: 90% !important;
  left: 1%;
}

/** End Modal **/

/** Tooltip **/
.tooltip-inner {
  background-color: var(--intermediate-color);
  color: #000;
  font-weight: 600;
}

.color-white > .tooltip-inner {
  color: #fff !important;
  font-weight: normal;
}

#right-close-button > .btn-close {
  top: 25px;
  position: absolute;
  right: 30px;
}

.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before,
.bs-tooltip-end .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: var(--intermediate-color);
}

.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before,
.bs-tooltip-top .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: var(--intermediate-color);
}
/** End Tooltip **/

/** Alert **/
.alert {
  font-size: 14px;
}
/** End Alert **/

p .loading p {
  margin: 0 !important;
  margin-bottom: 0;
  position: relative;
}

.btn .spinner-border-sm {
  width: 0.7rem;
  height: 0.7rem;
}
.loading p:after {
  background-image: url("/src/img/spinner-white.gif");
  color: transparent;
  background-size: 100%;
  content: "..";
  font-size: 30pt;
}

h2 {
  font-family: var(--font-family-mulish);
  font-style: normal;
  font-weight: 600;
  font-size: 33px;
  line-height: 36px;
  color: #000000;
}

.breadcrumb-item {
  font-family: var(--font-family-mulish);
  font-size: 12px;
  line-height: 14px;
}

.breadcrumb-item.active {
  color: var(--disabled);
}

.breadcrumb-item + .breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, ">") !important ;
}

/* Drowdoan adapted to the colors of the platform to adapt it to the nav of the tabs. */
.nav-dropdown-cm {
  background-color: var(--red-primary);
  border-radius: 4px;
  cursor: pointer;
}

.nav-dropdown-cm > a {
  color: #fff !important;
}

.nav-dropdown-cm.nav-item.show.dropdown
  div.dropdown-menu.show
  a.dropdown-item.active {
  background-color: var(--red-primary);
  color: #fff !important;
}

/* This is the end of the nav dropdown block for tabs. */

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--red-primary);
  cursor: pointer;
}

.nav-link {
  color: var(--red-primary);
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
}

.nav-link.disabled {
  color: var(--disabled);
}

.layout-menu .nav {
  position: absolute;
  width: 100%;
  background-color: hotpink;
  background: #ffffff;
}
.layout-menu .nav-link {
  font-family: var(--font-family-mulish);
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  padding: 0.984009840098401vh;
  width: 100%;
  color: var(--black-primay);
}

.layout-menu .nav-item.dropdown {
  padding: 20px 0;
}

.layout-menu .nav-link:hover {
  color: var(--black-primay);
}

.layout-menu .nav-link.active {
  color: #ea2c54;
}

#content-organizations {
  position: relative;
  height: 6.71086vh;
}

#content-icons {
  position: relative;
  background-color: #ffffff;
  padding: 0px 0;
  overflow-x: hidden !important;
  overflow-y: scroll;
  height: 600px; /*Dinamic*/
}

#content-icons::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

#content-icons::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #cacaca;
}

#content-icons::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #fafafa;
}

.hide {
  display: none;
}

.popovers-content {
  background-color: #ffffff;
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
}

.popovers-content .nav-link {
  padding: 0;
}

.popover-notifications {
  max-height: 600px;
  overflow: scroll;
}

.popover-notifications::-webkit-scrollbar {
  display: none;
}

.error {
  position: absolute;
  color: red;
  font-size: 0.6rem;
  margin-top: 4px;
}

.errorValidation {
  background: #fff0f3;
  /*border: 1px solid #bfc2c6 !important;*/
  border-radius: 3px;
  border-width: 1px;
  height: 35px;
  width: 100%;
  border-color: var(--disabled);
}

.well {
  background: #fff;
  border: 1px solid #bfc2c6 !important;
  border-radius: 3px;
  border-width: 1px;
  height: 35px;
  width: 100%;
  border-color: var(--disabled);
}

.capitalize-text {
  text-transform: uppercase;
}

.print-source {
  display: none;
}

.mtop10 {
  margin-top: 10px;
}

.mtop20 {
  margin-top: 20px;
}

.mbottom10 {
  margin-bottom: 10px;
}

.mbottom20 {
  margin-bottom: 20px;
}

.helpBlockContainer {
  margin-top: -16px;
  margin-bottom: 35px;
}

.helpBlockContainerV2 {
  margin-top: -16px;
}

.containerDowloadZip {
  display: grid;
  place-items: center;
  margin-top: 20%;
  text-align: center;
}

.inputDatePicker {
  height: 28px;
  width: 100px;
  border: 1px solid;
  border-radius: 5px;
  padding-left: 10px;
}

.tabCM {
  background-color: #f6f6f6 !important;
  border-top: 1px solid #d6dadb !important;
  border-left: 1px solid #d6dadb !important;
  border-right: 1px solid #d6dadb !important;
  color: #5e5e5e !important;
  font-size: 12px;
  font-weight: bold;
}

.tabCM.nav-link.active {
  background-color: #fff !important;
  color: #2d2d2d !important;
}

.tabCM:hover {
  background-color: #5e5e5e !important;
  color: #2d2d2d !important;
}

.ql-editor img {
  max-width: 100%;
}

.list-item:focus,
.list-item:hover {
  color: #1e2125;
  background-color: var(--red-light);
}

.list-item.selected {
  color: #1e2125;
  background-color: var(--red-middle);
}

/**************************************************** Styles for Accordion *****************************************************/

.toggle-hide {
  display: none;
}

.toggle-show {
  transition: height 1s, opacity 1s;
}

.accordion {
  overflow-anchor: none !important;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card {
  overflow: hidden;
}
.accordion .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 0;
}

.accordion .card:hover {
  box-shadow: none;
}

.accordion > .card > .card-header {
  border-radius: 0;
}

.accordion > .card > .card-header p {
  font-family: "Open Sans", sans-serif;
  font-size: 14px !important;
  color: #626262;
  width: fit-content;
  cursor: pointer;
  margin-bottom: 0;
}
.accordion > .card > .card-header p:hover {
  color: #2c2c2c;
}

.accordion > .card > .card-header .fa-angle-down,
.accordion > .card > .card-header .fa-angle-up {
  font-size: 18px;
  cursor: pointer;
}

.accordion > .card > .card-header .fa-trash-alt {
  font-size: 13px;
  cursor: pointer;
  margin-right: 25px;
}

.accordion > .card > .card-header .fa-trash-alt:hover {
  color: red;
}

/* Put the border radius to the first element of the accordion */
.accordion .card:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

/* Put the border radius to the last element of the accordion */
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card-header {
  padding: 1.5rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
}

.accordion > .card > .card-body {
  border-radius: 0;
  margin-bottom: -1px;
  background-color: #ffffff;
  padding: 20px 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.row-subconcept {
  width: 100%;
  display: inline-block;
  margin-bottom: 7px;
}

.link-add-student {
  text-decoration: none;
}

/**************************************************** End Styles for Accordion *****************************************************/

/** Images menu **/
#item-dashboard:hover {
  content: url("/src/img/dashboard-icons/hover/dashboard.svg");
}

#item-app:hover {
  content: url("/src/img/dashboard-icons/hover/app.svg");
}

#item-crm:hover {
  content: url("/src/img/dashboard-icons/hover/crm.svg");
}

#item-payments:hover {
  content: url("/src/img/dashboard-icons/hover/payments.svg");
}

#item-students:hover {
  content: url("/src/img/dashboard-icons/hover/students.svg");
}

#item-relatives:hover {
  content: url("/src/img/dashboard-icons/hover/relatives.svg");
}

#item-events:hover {
  content: url("/src/img/dashboard-icons/hover/events.svg");
}

#item-news:hover {
  content: url("/src/img/dashboard-icons/hover/news.svg");
}

#item-class:hover {
  content: url("/src/img/dashboard-icons/hover/class.svg");
}

#item-reviews:hover {
  content: url("/src/img/dashboard-icons/hover/reviews.svg");
}

#item-admin:hover {
  content: url("/src/img/dashboard-icons/hover/admin.svg");
}

#item-teachers:hover {
  content: url("/src/img/dashboard-icons/hover/staff.svg");
}

#item-reports:hover {
  content: url("/src/img/dashboard-icons/hover/reports.svg");
}

#item-website:hover {
  content: url("/src/img/dashboard-icons/hover/website.svg");
}

#item-configuration:hover {
  content: url("/src/img/dashboard-icons/hover/configuration.svg");
}

#item-training:hover {
  content: url("/src/img/dashboard-icons/hover/training.svg");
}

#item-chat:hover {
  filter: var(--filter-base) !important;
}

#item-notifications:hover {
  filter: var(--filter-base) !important;
}

#item-google:hover {
  filter: var(--filter-base) !important;
}

#item-user:hover {
  filter: var(--filter-base) !important;
}

#item-permissions:hover {
  content: url("/src/img/dashboard-icons/hover/permission.svg");
}

.layout-menu .nav-link.active #item-dashboard {
  content: url("/src/img/dashboard-icons/active/dashboard.svg");
  filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.25));
}

.layout-menu .nav-link.active #item-app {
  content: url("/src/img/dashboard-icons/active/app.svg");
  filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.25));
}

.layout-menu .nav-link.active #item-crm {
  content: url("/src/img/dashboard-icons/active/crm.svg");
  filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.25));
}

.layout-menu .nav-link.active #item-payments {
  content: url("/src/img/dashboard-icons/active/payments.svg");
  filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.25));
}

.layout-menu .nav-link.active #item-students {
  content: url("/src/img/dashboard-icons/active/students.svg");
  filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.25));
}

.layout-menu .nav-link.active #item-relatives {
  content: url("/src/img/dashboard-icons/active/relatives.svg");
  filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.25));
}

.layout-menu .nav-link.active #item-events {
  content: url("/src/img/dashboard-icons/active/events.svg");
  filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.25));
}

.layout-menu .nav-link.active #item-news {
  content: url("/src/img/dashboard-icons/active/news.svg");
  filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.25));
}

.layout-menu .nav-link.active #item-class {
  content: url("/src/img/dashboard-icons/active/class.svg");
  filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.25));
}

.layout-menu .nav-link.active #item-reviews {
  content: url("/src/img/dashboard-icons/active/reviews.svg");
  filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.25));
}

.layout-menu .nav-link.active #item-admin {
  content: url("/src/img/dashboard-icons/active/admin.svg");
  filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.25));
}

.layout-menu .nav-link.active #item-teachers {
  content: url("/src/img/dashboard-icons/active/staff.svg");
  filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.25));
}

.layout-menu .nav-link.active #item-reports {
  content: url("/src/img/dashboard-icons/active/reports.svg");
  filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.25));
}

.layout-menu .nav-link.active #item-website {
  content: url("/src/img/dashboard-icons/active/website.svg");
  filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.25));
}

.layout-menu .nav-link.active #item-configuration {
  content: url("/src/img/dashboard-icons/active/configuration.svg");
  filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.25));
}

.layout-menu .nav-link.active #item-training {
  content: url("/src/img/dashboard-icons/active/training.svg");
  filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.25));
}

.layout-menu .nav-link.active #item-permissions {
  content: url("/src/img/dashboard-icons/active/permission.svg");
  filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.25));
}

.popovers-content .nav-link.active #item-chat {
  content: url("/src/img/dashboard-icons/active/icon_chat.svg");
  filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.25));
}

.popovers-content .nav-link.active #item-notifications {
  content: url("/src/img/dashboard-icons/active/icon_notifications.svg");
  filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.25));
}

.popovers-content .nav-link.active #item-google {
  content: url("/src/img/dashboard-icons/active/icon_google.svg");
  filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.25));
}

.popovers-content .nav-link.active #item-user {
  content: url("/src/img/dashboard-icons/active/icon_user.svg");
  filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.25));
}

/** End Images menu **/

/** Services Premium */
.premium-service,
.premium-icon {
  position: relative;
  width: fit-content;
  margin: 0 auto;
}
.premium-icon-mobile {
  position: relative;
  width: fit-content;
  margin: 0 auto;
}

.premium-service:after,
.premium-icon:after {
  content: url(/src/img/dashboard-icons/crown.svg);
  position: absolute;
  width: 2.5830258302583027vh !important;
  filter: invert(60%) sepia(97%) saturate(499%) hue-rotate(358deg)
    brightness(101%) contrast(107%);
}

.premium-icon-mobile:after {
  content: url(/src/img/dashboard-icons/crown.svg);
  position: absolute;
  width: 2.5830258302583027vh !important;
  filter: invert(60%) sepia(97%) saturate(499%) hue-rotate(358deg)
    brightness(101%) contrast(107%);
}
.premium-service:after {
  right: -15px;
  top: -7px;
}
.premium-icon:after {
  right: -24px;
  top: -13px;
}

.premium-icon-mobile:after {
  right: -8px;
  top: -3px;
}
.icon-google {
  padding: 10px;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
}

.icon-google:hover {
  background-color: #f0f0f0;
}
/** End Services Premium */

/** Overlay block */
.box-maintenance {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  z-index: 1000;
  height: max-content;
  width: 390px;
  left: 50%;
  top: 50% !important;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  border-radius: 4px;
  border: transparent;
  border-radius: 10px;
  padding: 40px 20px 50px 20px;
  text-align: center;
  font-size: 18px !important;
  font-weight: 600 !important;
}

.overlay-maintenance:before {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.overlay-maintenance:before {
  content: "";
  position: fixed;
  top: 0 !important;
  height: auto !important;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
  z-index: 999;
  width: 100%;
  height: 100vh;
}

.disabled-scroll {
  overflow: hidden;
}
/** End Overlay block */

/** Input error */
.form-label {
  font-weight: bold;
  font-size: 14px;
}

.form-control:focus,
.form-check-input:focus {
  border-color: #ffb7c4 !important;
  box-shadow: 0 0 0 0.25rem rgb(255 217 224) !important;
}

.form-control::-moz-placeholder {
  color: #d4d4d4;
  font-weight: 400;
}

.form-control:-ms-input-placeholder {
  color: #d4d4d4;
  font-weight: 400;
}

.form-control::-webkit-input-placeholder {
  color: #d4d4d4;
  font-weight: 400;
}

.form-select:focus,
.form-check-input:focus {
  border-color: #ffb7c4 !important;
  box-shadow: 0 0 0 0.25rem rgb(255 217 224) !important;
}

.form-select::-moz-placeholder {
  color: #d4d4d4;
  font-weight: 400;
}

.form-select:-ms-input-placeholder {
  color: #d4d4d4;
  font-weight: 400;
}

.form-select::-webkit-input-placeholder {
  color: #d4d4d4;
  font-weight: 400;
}

.text-muted.error {
  color: var(--danger) !important;
}

.content-button > * {
  margin: 0.25rem;
}

.crown-front-icon-LP {
  font-size: 25px !important;
  padding-bottom: 6px !important;
  vertical-align: bottom !important;
  color: #ffb300;
  position: relative;
  text-align: center;
}

.premiumText-informationModal-LP {
  font-size: 23px !important;
  font-weight: 600;
  padding-bottom: 6px !important;
  vertical-align: bottom !important;
  color: #ffb300;
}

.crown-front-icon-LP:before {
  content: url("/src/img/dashboard-icons/crown.svg");
  position: absolute;
  left: -30px;
  top: 0;
  width: 25px !important;
  filter: invert(60%) sepia(97%) saturate(499%) hue-rotate(358deg)
    brightness(101%) contrast(107%);
}

.over-list:hover {
  background-color: var(--gray);
}

.over-list-click:hover {
  cursor: pointer;
}

.layout-views {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 0;
  padding-right: 0;
}

:focus-visible {
  outline: none;
}

.modal-footer div > * {
  margin: 0.25rem;
}

.modal-footer div div > * {
  margin: 0;
}

.for-element {
  display: inline-block;
}

.lazyLoading div {
  height: 19px;
  width: 100%;
  margin-bottom: 4px;
  left: 20px;
  position: relative;
  animation: anim 2s forwards infinite linear;
}

.lazyLoading div {
  background: #ffffff no-repeat 800px 104px;
  background-size: 100%;
  background-image: -webkit-gradient(
    linear,
    0% 50%,
    100% 50%,
    color-stop(0%, #75b4f3),
    color-stop(20%, #466feb),
    color-stop(40%, #3b98f5),
    color-stop(100%, #47a0f8)
  );
}

/****************** Lazy Loading ******************/
.table-lazyLoading {
  background: #ffffff;
}

.table-lazyLoading div div {
  height: 35px;
  margin-bottom: 4px;
  left: 0;
  position: relative;
  background: #f7f7f7 no-repeat 800px 104px;
  background-size: 100%;
  background-image: -webkit-gradient(
    linear,
    0% 50%,
    100% 50%,
    color-stop(0%, #f7f7f7),
    color-stop(20%, #eaeaea),
    color-stop(40%, #f7f7f7),
    color-stop(100%, #f7f7f7)
  );
  animation: anim 2s forwards infinite linear;
}

@keyframes anim {
  0% {
    background-position: 0px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
/*************** End Lazy Loading *****************/

.disbled-element {
  opacity: 0.3;
  cursor: not-allowed !important;
}

/*Day selected in date-picker*/
.selected-day {
  background-color: var(--base-color);
  color: var(--white-color);
  font-weight: bold;
}

.react-datepicker__month-text--keyboard-selected {
  background-color: var(--base-color);
  color: var(--white-color);
  font-weight: bold;
}
.react-datepicker__month-text.react-datepicker__month--selected:hover {
  background-color: var(--base-color);
  color: var(--white-color);
  font-weight: bold;
}
.reactDataPicker-monthView {
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: 400;
}

.select-group {
  flex: 1 1 auto;
  width: min-content;
}

.select-group.errorValidation,
.select.errorValidation {
  /*border: none !important;*/
  height: auto !important;
}

.select-group.errorValidation .select-group__control,
.select.errorValidation .select__control {
  background-color: #fff0f3;
  /*border: none !important;
  height: auto !important;*/
}

@media only screen and (min-width: 2560px) {
  .premium-service:after {
    right: -30px;
    top: -7px;
  }
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
}

.react-datepicker__navigation--years {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: #ccc;
}

.react-datepicker__navigation--years.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #ccc;
}

.react-datepicker__day--disabled {
  cursor: not-allowed !important;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: none;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  text-transform: none;
}
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  text-transform: none;
}

.input-group .rdt.rdtOpen .rdtPicker {
  top: 39px;
}

.input-group .rdt.rdtOpen .form-control:focus {
  border: 1px solid rgb(192, 192, 192) !important;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.input-group-addon {
  padding: 6px 12px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input-group input:disabled {
  cursor: not-allowed;
}

/*** react select*/
.scholarship-select .css-1rhbuit-multiValue {
  border-radius: 50px !important;
  background-color: #f18085 !important;
  color: #ffffff !important;
}

.scholarship-select .css-1rhbuit-multiValue .css-12jo7m5 {
  color: #ffffff !important;
}

.scholarship-select .css-1rhbuit-multiValue .css-xb97g8 {
  border-radius: 50px !important;
}

.scholarship-select .css-1hb7zxy-IndicatorsContainer {
  display: none;
}
.scholarship-select .css-1wy0on6 {
  display: none;
}

.select--is-disabled.css-3iigni-container {
  pointer-events: unset;
}

.select--is-disabled.css-3iigni-container .css-cqqqxm-control {
  cursor: not-allowed;
}

.css-1s2u09g-control,
.css-1n7v3ny-option,
.select__option {
  cursor: pointer !important;
}

/* Skeleton loading */
.skeleton {
  display: block;
  background: linear-gradient(
      to right,
      rgba(238, 238, 238, 0),
      rgba(238, 238, 238, 0.5) 50%,
      rgba(238, 238, 238, 0) 80%
    ),
    lightgray;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}

@keyframes shine {
  to {
    background-position: 100% 0;
  }
}

/* start overlayBlock */
.overlayBlock-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.overlayBlock-content {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(202, 202, 202, 0.693);
  transition: 0.5s;
  align-items: center;
  justify-content: center;
  display: flex;
}

.overlayBlock-elements {
  position: absolute;
  text-align: center;
}
/* end overlayBlock */

.row-body {
  background: #ffffff;
  border: 1px solid #ececee;
  border-radius: 0px;
  height: 50px;
}

.row-body:hover {
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
}

/* Firefox  */
.scrollbar-hidden {
  scrollbar-width: none;
  scrollbar-color: #afafaf #d3d3d3;
}

/* Chrome, Opera, Edge, etc.  */
.scrollbar-hidden::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.scrollbar-hidden::-webkit-scrollbar-track {
  background-clip: content-box;
  border: 2px solid transparent;
}

.scrollbar-hidden::-webkit-scrollbar-thumb {
  background-color: #afafaf;
}

.scrollbar-hidden::-webkit-scrollbar-thumb:hover {
  background-color: #afafaf;
}

.scrollbar-hidden::-webkit-scrollbar-corner,
.scrollbar-hidden::-webkit-scrollbar-track {
  background-color: #d3d3d3;
}

.css-b62m3t-container .css-p2mrbf-control {
  border-color: #ffb7c4 !important;
  box-shadow: 0 0 0 0.25rem rgb(255 217 224) !important;
}

a {
  color: #2c2c2c;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #23527c;
  text-decoration: underline;
}

/*** react select for multiple selection with pill style*/
.multiplePillSelect .css-1rhbuit-multiValue {
  border-radius: 50px !important;
  background-color: #f18085 !important;
  color: #ffffff !important;
}

.multiplePillSelect .css-1rhbuit-multiValue .css-12jo7m5 {
  color: #ffffff !important;
}

.multiplePillSelect .css-1rhbuit-multiValue .css-xb97g8 {
  border-radius: 50px !important;
}

.multiplePillSelect .select__indicator-separator {
  display: none;
}

.multiplePillSelect .select__clear-indicator {
  display: none;
}

/* section subconcepts */
.subconceptItems {
  background: #fff;
}

.subconceptItemsActive {
  background: #ea2c54;
  color: #fff;
}
.subconceptItemsActive:hover .subconceptEditIcons {
  display: block;
}

.subconceptEditIcons {
  display: none;
}

.subconceptItems:hover {
  background: var(--lightest-color);
}

.subconceptItems:hover .subconceptEditIcons {
  display: block;
}

.productItem:hover .subconceptEditIcons {
  display: block;
}

.narrowFinder .css-b62m3t-container {
  height: 20px !important;
}

.narrowFinder .css-b62m3t-container .css-1s2u09g-control {
  min-height: 28px !important;
  height: 28px;
  align-content: center;
  border-color: #6c757d;
}
/*  aplicar mismos esilos para conrol cuando este deshailitado */
.narrowFinder .css-3iigni-container {
  pointer-events: unset;
}

.narrowFinder .css-1insrsq-control {
  min-height: 28px;
  height: 28px;
  align-content: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: not-allowed;
}

/* Remover css-1okebmr-indicatorSeparator */
.narrowFinder .css-b62m3t-container .css-1okebmr-indicatorSeparator {
  display: none;
}

.center-flex {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
/* section subconcepts ^^^^ */
.innerShadow:hover {
  -webkit-box-shadow: inset 0px 0px 24px -13px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: inset 0px 0px 24px -13px rgba(0, 0, 0, 0.7);
  box-shadow: inset 0px 0px 24px -13px rgba(0, 0, 0, 0.7);
}
.externalShadow:hover {
  -webkit-box-shadow: 0px 0px 24px -13px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0px 0px 24px -13px rgba(0, 0, 0, 0.7);
  box-shadow: 0px 0px 24px -13px rgba(0, 0, 0, 0.7);
}

.hidden-container {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.visible-container {
  visibility: visible;
  opacity: 1;
}
